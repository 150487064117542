<template>
  <div class="compilations-folders">
    <span class="compilations-folders__title">{{ getTitle }}</span>
    <Draggable
    :list="getFolders"
    v-bind="dragOptions"
    class="compilations-folders__folders-list"
    @choose="chooseDragElement"
    @end="endDrag"
    @change="changeDragInList">
      <div
      v-for="folder in getFolders"
      :key="folder.id"
      class="compilations-folders__folder-container"
      @dragover.prevent="setHoverFolder(folder)"
      @dragleave.prevent="setHoverFolder(null)">
        <div
        class="compilations-folders__folder"
        :class="{
          'compilations-folders__folder_selected': getSelectedFolder?.id === folder.id,
          'compilations-folders__folder_hover': getHoverFolder?.id === folder.id,
        }">
          <div
          class="compilations-folders__name"
          @click="selectCurrentFolder(folder)">
            <span
            class="compilations-folders__text compilations-folders__text_folder"
            :class="{ 'compilations-folders__text_folder-selected': getSelectedFolder?.id === folder.id }">
              {{ folder.name }}
            </span>
            <span
            class="compilations-folders__text compilations-folders__text_folder-count"
            :class="{ 'compilations-folders__text_folder-selected': getSelectedFolder?.id === folder.id }">
              {{ (folder?.compilationAggregateCount || 0) + ' шт' }}
            </span>
          </div>
          <DataIcon
          v-if="!folder.isRoot"
          name="more_vert"
          :size="24"
          class="compilations-folders__menu-icon"
          :class="{ 'compilations-folders__menu-icon_selected': getSelectedFolder?.id === folder.id }"
          @click.native="openMenu(folder)" />
        </div>
        <transition name="fade">
          <div
          v-if="showMenu === folder.id"
          v-click-outside="closeMenu"
          class="compilations-folders__folder-menu">
            <!-- Переименование -->
            <div
            class="compilations-folders__folder-menu-item">
              <div
              v-if="!showRenameMenu"
              class="compilations-folders__folder-menu-element"
              @click.stop="renameFolder(folder)">
                <span class="compilations-folders__text">Переименовать</span>
              </div>
              <div
              v-else
              class="compilations-folders__rename-block">
                <input
                ref="renameInput"
                v-model="newName"
                class="compilations-folders__rename-input"
                placeholder="Новое название"
                @keyup.enter="acceptRename">
                <DataIcon
                name="done"
                pointer
                @click.native="acceptRename" />
              </div>
            </div>

            <!-- Удаление -->
            <div
            class="compilations-folders__folder-menu-item"
            @click.stop="deleteFolder(folder)">
              <div class="compilations-folders__folder-menu-element">
                <span class="compilations-folders__text">Удалить папку</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </Draggable>
    <div class="compilations-folders__folders-overlay" />
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'CompilationsFolders',
  components: { Draggable },
  props: {
    folders: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    showMenu: false,
    showDeleteMenu: false,
    showRenameMenu: false,
    newName: '',
    dragFolder: null,
  }),
  methods: {
    openMenu(folder) {
      this.closeMenu();
      this.showMenu = folder.id;
    },
    closeMenu() {
      this.showDeleteMenu = false;
      this.showRenameMenu = false;
      this.showMenu = false;
      this.currentFolder = null;
      this.newName = '';
    },
    selectCurrentFolder(folder) {
      if (!folder) {
        const rootFolder = this.folders.find((item) => item.isRoot);
        this.$store.commit('compilations/setSelectedCompilationsFolder', rootFolder);
        this.$store.dispatch('compilations/getCompilationsInFolder', rootFolder.id);
      } else {
        this.$store.commit('compilations/setSelectedCompilationsFolder', folder);
        this.$store.dispatch('compilations/getCompilationsInFolder', folder.id);
      }
      this.showMenu = false;
    },
    setHoverFolder(value) {
      if (this.dragFolder) return;
      this.$store.commit('compilations/setDraggableFolder', value);
    },
    deleteFolder(folder) {
      this.$store.dispatch('compilations/deleteFolderForCompilation', folder.id);
      if (this.getSelectedFolder?.id === folder.id) {
        this.selectCurrentFolder();
      }
    },
    renameFolder(folder) {
      this.showRenameMenu = !this.showRenameMenu;
      this.newName = '';
      this.currentFolder = this.currentFolder ? null : folder;
      this.$nextTick(() => {
        this.$refs.renameInput[0].focus();
      });
    },
    acceptRename() {
      if (!this.currentFolder || !this.newName.length) return;
      const params = { folderId: this.currentFolder.id, folderName: this.newName };
      this.$store.dispatch('compilations/renameFolderForCompilation', params);
      this.closeMenu();
    },
    chooseDragElement(event) {
      this.dragFolder = this.getFolders[event.oldIndex];
    },
    endDrag() {
      this.dragFolder = null;
    },
    changeDragInList(event) {
      const { element } = event.moved;
      const { newIndex } = event.moved;
      this.dragFolder = null;

      const userId = this.$store.getters['user/getUserInfo']?.id;
      const prevElementIndex = this.getFolders[newIndex - 1]?.id;
      const folderId = prevElementIndex ? this.getFolders[newIndex - 1]?.id : this.getFolders[newIndex + 1]?.id;
      const place = prevElementIndex ? 'after' : 'before';

      const params = {
        payload: {
          folderId: element.id,
          destinationFolderId: folderId,
          place,
        },
        userId,
      };

      this.$store.dispatch('compilations/moveFolderInFolderList', params)
        .catch((error) => {
          console.warn(error);
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте повторить позже', error: true });
        });
    },
  },
  computed: {
    getFolders: {
      get() {
        if (!this.folders.length) return [];
        return this.folders;
      },
      set(value) {
        this.folders = value;
      },
    },
    getSelectedFolder() {
      return this.$store.getters['compilations/getSelectedCompilationsFolder'];
    },
    getHoverFolder() {
      return this.$store.getters['compilations/getDraggableFolder'];
    },
    getTitle() {
      return `Папки (${this.getFolders.length === 0 ? 1 : this.getFolders.length})`;
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'compilations-folders__drag-folder',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.compilations-folders {
  width: 100%;
  margin-right: 20px;
  padding-right: 0px;
  background: white;
  max-width: 350px;
  border-radius: 12px;
  outline: 1px solid #D8F2FA;
  flex: 1;
  background: #fafeff;
  position: relative;

  .compilations-folders__title {
    font-size: 14px;
    color: $primary-gray;
    padding: 20px 20px 0px 20px;
    display: block;
  }

  .compilations-folders__folders-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    height: 100%;
    overflow: auto;
    padding: 20px 22px 200px 20px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      cursor: pointer;
    }
  }

  .compilations-folders__folder-container {
    position: relative;
  }

  .compilations-folders__folder {
    border-radius: 12px;
    background: white;
    color: $primary-blue;
    cursor: pointer;
    position: relative;
    transition: 0.1s;
    outline: 1px solid #aedff0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      transition: 0.2s;
      box-shadow: 2px 0px 10px 3px rgb(34 60 80 / 20%);
    }
    &_selected {
      background: $primary-blue;
      outline: none;
    }
    &_hover {
      transform: scale(105%);
      box-shadow: 2px 0px 10px 3px rgb(34 60 80 / 10%);
    }
  }

  .compilations-folders__name {
    width: 100%;
    text-overflow: ellipsis;
    padding: 10px 30px 10px 20px;
  }

  .compilations-folders__folder-menu {
    position: absolute;
    top: 50px;
    right: 0px;
    border-radius: 6px;
    width: 180px;
    background: white;
    transition: 0.2s;
    box-shadow: 2px 0px 10px 1px rgb(34 60 80 / 20%);
    z-index: 3;
    min-height: 80px;
    overflow: hidden;
  }

  .compilations-folders__folder-menu-item {
    display: flex;
    justify-content: flex-start;
    max-width: 180px;
  }

  .compilations-folders__folders-overlay {
    position: absolute;
    bottom: 0px;
    left: 1px;
    background: linear-gradient(0deg, #FAFEFF, #01CEFF00);
    width: 344px;
    height: 200px;
    border: 12px;
    border-radius: 12px;
    pointer-events: none;
  }

  .compilations-folders__folder-menu-element {
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    height: 40px;
    &:hover {
      background: rgb(248, 248, 248);
    };
  }

  .compilations-folders__rename-block {
    padding: 5px 10px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
  }

  .compilations-folders__action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px;
    cursor: pointer;
    height: 40px;
    box-sizing: border-box;
    &_selected {
      outline: 1px solid $primary-lightgray;
      border-radius: 8px;
      background: rgb(250, 250, 250);
    }
    @include between-elements {
      margin-right: 10px;
    }
  }

  .compilations-folders__icon {
    width: 25px;
  }

  .compilations-folders__text {
    font-size: 14px;
    color: $primary-gray;
    text-align: center;
    &_folder {
      color: $primary-blue;
      pointer-events: none;
      text-overflow: ellipsis;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 7px;
    }
    &_folder-count {
      color: $primary-blue;
      font-size: 12px;
      font-weight: 400;
      pointer-events: none;
    }
    &_folder-selected {
      color: white;
      pointer-events: none;
    }
  }

  .compilations-folders__rename-input {
    outline: none;
    border: none;
    max-width: 140px;
  }

  .compilations-folders__menu-icon {
    color: rgba($primary-blue, 0.6);
    padding: 15px 10px 15px 10px;
    box-sizing: content-box;
    display: block;
    &_selected {
      color: white;
    }
  }
}

.compilations-folders__drag-folder {
  opacity: 0.1;
}
</style>
