<template>
  <div
  class="client-element"
  :class="isOpen && 'client-element_open'">
    <div class="client-element__row">
      <div
      class="client-element__column client-element__column_name"
      @click="clientNameHandler">
        <div class="client-element__name-text">
          <span
          class="client-element__text client-element__text_name">
            {{ getClientName() }}
          </span>
          <div class="client-element__access">
            <img
            class="client-element__access-icon"
            :src="require(`@/assets/svg/icons/${isAccess ? 'circle-ok.svg' : 'circle-false.svg'}`)">
            <span
            class="client-element__text client-element__text_light">
              {{ getExpiredDate() }}
            </span>
          </div>
          <DataIcon
          name="expand_more"
          :size="24"
          :style="!client.employees?.length && 'display: none'"
          class="client-element__text_icon"
          :arrow-open="isOpen" />
        </div>
      </div>

      <!-- Запросы -->
      <div
      class="client-element__column"
      style="width: 120px">
        <span class="client-element__text">
          {{ client.requestPlacementsCount !== null ? client.requestPlacementsCount : '—' }}
        </span>
      </div>

      <!-- Клиенты -->
      <div
      v-if="isAdmin"
      class="client-element__column"
      style="width: 120px">
        <span class="client-element__text">
          {{ client.clients?.length }}
        </span>
      </div>

      <!-- Кампании -->
      <div
      class="client-element__column"
      style="width: 120px">
        <span class="client-element__text">
          {{ client.campaignsCount !== null ? client.campaignsCount : '—' }}
        </span>
      </div>

      <!-- Потрачено за мес/всего -->
      <div
      class="client-element__column"
      style="width: 200px">
        <span class="client-element__text client-element__text_commit">
          {{ `${getClientBurnCommit} / ${getClientBurnTotal}` }}
        </span>
      </div>

      <!-- Комиссия -->
      <div
      class="client-element__column"
      style="width: 100px">
        <span class="client-element__text">
          {{ getRate }}
        </span>
      </div>

      <!-- Депозит -->
      <div
      class="client-element__balance"
      style="width: 150px">
        <div
        v-if="disabledBalanceSystem"
        class="client-element__balance-block">
          <div class="client-element__balance-value client-element__balance-value_off">
            <span class="client-element__text client-element__text_balance">
              Выкл
            </span>
          </div>
        </div>
        <div
        v-else
        class="client-element__balance-block">
          <div
          class="client-element__balance-value"
          :class="negativeClientBalance && 'client-element__balance-value_negative'">
            <span class="client-element__text client-element__text_balance">
              {{ getClientBalance }}
            </span>
          </div>
          <DataIcon
          v-show="isSalesManager || isAccountManager"
          name="edit"
          class="client-element__balance-edit-icon"
          :size="15"
          @click.native="showEditBalance = true" />
        </div>
        <transition
        :key="showEditBalance"
        name="fastfade">
          <ClientBalance
          v-if="showEditBalance"
          v-click-outside="() => showEditBalance = false"
          class="client-element__balance-editor"
          :client="client"
          @close="showEditBalance = false" />
        </transition>
      </div>

      <!-- Аккаунт -->
      <div
      v-if="isAdmin"
      class="client-element__responsible"
      style="width: 300px">
        <DataSelect
        ref="accountSelect"
        v-model="selectedAccountManagers"
        :options="accountManagers"
        select-type="multi"
        placeholder="Выберите аккаунт менеджеров"
        @input="setAccountsManagers" />
      </div>

      <!-- Сейлз -->
      <div
      v-if="isAdmin"
      class="client-element__responsible"
      style="width: 300px">
        <DataSelect
        v-model="selectedSalesManagers"
        :options="salesManagers"
        select-type="multi"
        placeholder="Выберите sales менеджеров"
        @input="setSalesManagers" />
      </div>

      <!-- Действия -->
      <div
      class="client-element__column"
      style="width: 50px">
        <img
        src="@/assets/svg/icons/menu.svg"
        class="client-element__menu-icon"
        @click="openMenu">
        <div
        v-if="showMenu"
        v-click-outside="() => showMenu = false"
        class="client-element__menu"
        :class="lastElement && 'client-element__menu_last'">
          <span
          class="client-element__menu-element"
          @click="openDeleteClient">Удалить</span>
          <span
          class="client-element__menu-element"
          @click="openEditClient">Редактировать</span>
          <span
          v-if="isAdmin"
          class="client-element__menu-element"
          @click="downloadStatisticReport">Загрузить статистику</span>
        </div>
      </div>
    </div>
    <div
    v-if="isOpen && client.employees?.length"
    class="client-element__users">
      <UserElement
      v-for="user in client.employees"
      :key="user.id"
      :user="user"
      :current-client="client"
      in-client
      @deleteEmployeeFromClient="deleteEmployeeFromClient"
      @openEditEmployeeAgency="$emit('openEditEmployeeAgency', user)" />
    </div>
  </div>
</template>

<script>
import UserElement from '../Users/UserElement.vue';
import ClientBalance from './ClientBalance.vue';

export default {
  name: 'ClientElement',
  components: { UserElement, ClientBalance },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
    accountManagers: {
      type: Array,
      default: null,
    },
    salesManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    showMenu: false,
    isAccess: false,
    selectedAccountManagers: null,
    selectedSalesManagers: null,
    showEditBalance: false,
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.selectedAccountManagers = this.client?.responsibleUsers
        ?.filter((item) => item.role === 6)
        .map((item) => ({ name: item.name || item.email, value: item.id })) || [];
      this.selectedSalesManagers = this.client?.responsibleSalesManagerUsers?.map((item) => ({ name: item.name || item.email, value: item.id })) || [];
    },
    setAccountsManagers() {
      const params = {
        responsibleUsersIds: this.selectedAccountManagers.map((item) => item.value),
        agencyId: this.client.id,
      };
      this.$store.dispatch('clients/updateAccountManagers', params)
        .then(() => this.$store.dispatch('app/setSnackBar', { text: 'Обновлено' }));
    },
    setSalesManagers() {
      const params = {
        responsibleUsersIds: this.selectedSalesManagers.map((item) => item.value),
        agencyId: this.client.id,
      };
      this.$store.dispatch('clients/updateSalesManagers', params)
        .then(() => this.$store.dispatch('app/setSnackBar', { text: 'Обновлено' }));
    },
    clientNameHandler() {
      if (this.isAdmin) {
        this.toggleClientUsers();
        return;
      }
      if (this.isAgency) {
        this.$emit('openEditClientAgency', this.client);
      }
    },
    toggleClientUsers() {
      if (this.isOpen) {
        this.isOpen = false;
        return;
      }
      // перед открытием списка юзеров вызов закрытия подменю с сотрудниками на всех элементах клиентов
      this.$emit('closeAllClients');
      this.isOpen = true;
    },
    openDeleteClient() {
      this.$emit('openDeleteClient', { client: this.client, name: this.getClientName() });
    },
    deleteEmployeeFromClient(user, client) {
      this.$store.dispatch('clients/deleteEmployeeFromAgency', { agencyId: client.id, userId: user.id });
    },
    openEditClient() {
      this.showMenu = false;
      if (this.isAdmin) {
        this.$emit('openEditAgency', this.client);
        return;
      }
      if (this.isAgency) {
        this.$emit('openEditClientAgency', this.client);
      }
    },
    closeUsers() {
      this.isOpen = false;
    },
    getExpiredDate() {
      try {
        if (this.isAdmin) {
          this.isAccess = this.client.currentTariff?.tariff?.isAccessToCatalog || false;
          const expiredDateAgency = this.client.currentTariff?.expiredDateTime?.date || false;
          if (this.isAccess) {
            return expiredDateAgency ? `Доступ до ${this.moment(expiredDateAgency).format('ll')}` : 'Доступ открыт';
          }
          return 'Доступ закрыт';
        }
        if (this.isAgency) {
          this.isAccess = this.client.isAccessToAgency;
          const expiredDateAgency = this.getUserInfo?.asEmployerAgency?.currentTariff?.expiredDateTime?.date || false;
          if (this.isAccess) {
            return expiredDateAgency ? `Доступ до ${this.moment(expiredDateAgency).format('ll')}` : 'Доступ открыт';
          }
          return 'Доступ закрыт';
        }
        return '';
      } catch {
        return '';
      }
    },
    getClientName() {
      if (this.isAgency) {
        return this.client.companyName || this.client.name;
      }
      return this.client.name;
    },
    openMenu() {
      this.showMenu = true;
    },
    downloadStatisticReport() {
      const params = { agencyId: this.client.id, name: this.client.name };
      this.$store.dispatch('clients/downloadStatisticReport', params);
    },
  },
  computed: {
    getRate() {
      return this.client.baseCommissionInPercent ? `${this.client.baseCommissionInPercent}%` : '—';
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    getClientBurnTotal() {
      return this.client.burnMarketRateAccount?.burnMarketRate
        ? `${this.setNumberSpaces(this.client.burnMarketRateAccount?.burnMarketRate)} ₽`
        : '—';
    },
    getClientBurnCommit() {
      return this.client.burnMarketRateAccount?.commit
        ? `${this.setNumberSpaces(this.client.burnMarketRateAccount?.commit)} ₽`
        : '—';
    },
    getClientBalance() {
      const balance = this.client.account?.balance || 0;
      return `${this.setNumberSpaces(balance)} ${this.getCurrencySymbol}`;
    },
    negativeClientBalance() {
      const balance = this.client.account?.balance || 0;
      return balance < 0;
    },
    disabledBalanceSystem() {
      return !this.client.hasBalance;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
    isSalesManager() {
      return this.$store.getters['user/getIsSalesManager'];
    },
    isAccountManager() {
      return this.$store.getters['user/getIsManager'];
    },
  },
};
</script>

<style lang="scss" scoped>

.client-element {
  min-width: 100%;
  border-bottom: 1px solid $primary-group-border;
  height: 70px;
  box-sizing: content-box;
  &_open {
    height: 100%;
  }
}

.client-element__users {
  min-width: 100%;
  background: white;
}

.client-element__access {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 5px;
  }
}

.client-element__access-icon {
  width: 16px;
  height: 15px;
}

.client-element__row {
  display: flex;
  align-items: center;
  min-width: 100%;
  top: 0px;
  left: 0px;
  height: 69px;
  width: 100%;
  &:hover {
    background: #f4fbfe;
    .client-element__column_name {
      background: #f4fbfe;
    }
  }
}

.client-element__column {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: relative;
  &_name {
    flex-direction: row;
    justify-content: flex-start;
    position: sticky;
    left: 0px;
    padding-left: 20px;
    background: white;
    cursor: pointer;
    z-index: 1;
    @include tablet-screen {
      width: 400px;
    }
  }
}

.client-element__name-text {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.client-element__text {
  font-size: 14px;
  overflow: hidden;
  &_name {
    font-size: 16px;
    white-space: normal;
    color: $primary-blue;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  &_light {
    color: $primary-lightgray;
    font-size: 12px;
  }
  &_commit {
    color: $primary-gray;
    font-size: 14px;
  }
  &_icon {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 25px;
    transition: 0.2s;
    color: rgba($primary-lightgray, 0.5);
  }
  &_balance {
    font-size: 13px;
    color: white;
  }
}

.client-element__menu-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 18px;
  padding: 10px;
}

.client-element__menu {
  min-width: 210px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 2;
  &_last {
    top: initial;
    bottom: 50px;
  }
}

.client-element__menu-element {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: rgba($primary-lightgray, 0.05);
  }
}

.client-element__text_icon {
  opacity: 1;
}

.client-element__responsible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px;
}

.client-element__balance {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  &:hover {
    .client-element__balance-edit-icon {
      opacity: 1;
    }
  }
}

.client-element__balance-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.client-element__balance-value {
  padding: 7px 10px;
  border-radius: 4px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($primary-green, 0.8);
  color: white;
  &_off {
    background: rgba($primary-lightgray, 0.6);
  }
  &_negative {
    background: rgba($primary-red, 0.5);
  }
}

.client-element__balance-edit-icon {
  opacity: 0;
  transition: 0.2s;
  color: $primary-gray;
  position: absolute;
  top: 5px;
  right: -30px;
  cursor: pointer;
  margin-left: 10px;
}

.client-element__balance-editor {
  position: absolute;
  top: 60px;
  left: -180px;
}

</style>
