<template>
  <div class="post-search-screen">
    <PostSearchHeader />
    <div class="post-search-screen__content">
      <PostSearchFilters
      :is-loading="isLoading"
      @goSearch="goSearch" />
      <SearchPostsList
      :is-loading="isLoading"
      @goSearch="goSearch" />
    </div>
  </div>
</template>

<script>
import PostSearchHeader from './components/Header/PostSearchHeader.vue';
import PostSearchFilters from './components/Filters/PostSearchFilters.vue';
import SearchPostsList from './components/Posts/SearchPostsList.vue';

export default {
  name: 'PostSearchScreen',
  components: { PostSearchHeader, PostSearchFilters, SearchPostsList },
  data: () => ({
    isLoading: false,
    isInit: true,
  }),
  created() {
    this.$store.commit('searchPosts/setIsInitial', true);
  },
  mounted() {
    this.$store.commit('searchPosts/setLoadPage', this.$route.query.page || 1);
    this.goSearch();
  },
  methods: {
    goSearch() {
      const searchParams = this.$route.query;
      const hasRequireFields = [searchParams.social].every((item) => item);

      if (!hasRequireFields && this.isInit) {
        this.isInit = false;
        return;
      }

      if (!hasRequireFields) {
        this.$store.dispatch('app/setSnackBar', { text: 'Укажите социальную сеть' });
        return;
      }

      this.isLoading = true;
      this.$store.commit('searchPosts/setIsInitial', false);
      this.$store.dispatch('searchPosts/loadSearchPosts', { params: searchParams })
        .finally(() => {
          window.scrollTo({ top: 0 });
          this.isLoading = false;
        });
    },
  },
  computed: {
    getIsInitial() {
      return this.$store.getters['searchPosts/getIsInitial'];
    },
  },
};
</script>

<style lang="scss" scoped>
.post-search-screen {
  padding: 20px;
  width: 100%;
  height: 100%;

  .post-search-screen__content {
    display: flex;
    position: relative;
  }
}

</style>
