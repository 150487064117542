<template>
  <div class="group-actions-bar">
    <div class="group-actions-bar__block">
      <div
      class="group-actions-bar__button"
      @click="toggleShowSaveCollectionModal">
        <!-- NEED TRANSLATE -->
        Добавить в подборку
      </div>
      <div
      v-if="isAuthUser"
      class="group-actions-bar__button group-actions-bar__button_share"
      @click="createCurrentGroupLink">
        <img
        class="group-actions-bar__share-icon"
        :src="require('@/assets/svg/group/share-circle.svg')">
        Отправить презентацию
      </div>
    </div>
    <div class="group-actions-bar__block group-actions-bar__block_price">
      <div class="group-actions-bar__price-block">
        <span class="group-actions-bar__text group-actions-bar__text_title-gray">Размещение</span>
        <span class="group-actions-bar__text group-actions-bar__text_bold">{{ getPrice() }}</span>
      </div>
      <div
      v-tooltip="!isAuthUser ? '' : $t('Скоро')"
      class="group-actions-bar__button group-actions-bar__button_blue"
      @click="addToRk">
        <!-- NEED TRANSLATE -->
        <img
        :src="require('@/assets/svg/group/shopping-cart.svg')">
        <span>Добавить к РК</span>
      </div>
    </div>
    <DataModal
    :show-modal="showFormModal"
    @close="showFormModal = false">
      <template #modal-content>
        <LandingForm @close="showFormModal = false" />
      </template>
    </DataModal>
  </div>
</template>

<script>
import LandingForm from '@/components/MainLanding/LandingForm.vue';

export default {
  name: 'GroupActionsBar',
  components: { LandingForm },
  props: {
    group: {
      type: Object,
      default: null,
    },
    tradeOffer: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showFormModal: false,
  }),
  methods: {
    openSaveCollectionModal() {
      const modalParams = { name: 'SaveCollectionModal' };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    toggleShowSaveCollectionModal() {
      if (!this.isAuthUser) {
        this.showFormModal = true;
        return;
      }
      this.openSaveCollectionModal();
    },
    getPrice() {
      try {
        const postPrice = this.group.products.find((item) => item.type === 1); // находим объект с постом
        if (!postPrice) {
          return this.$t('no_data_available');
        }
        const resultPrice = postPrice.tradeOffersChain.tradeOffers.find((item) => item.buyerType === 2)?.resultPrice;
        if (!resultPrice && +resultPrice !== 0) {
          return this.$t('no_data_available');
        }
        return `${this.setNumberSpaces(Math.round(resultPrice))} ${this.getCurrencySymbol}`;
      } catch {
        return '?';
      }
    },
    createCurrentGroupLink() {
      const params = { id: this.group.id, social: this.group.social };
      this.$store.dispatch('group/createCurrentGroupLink', params)
        .then((data) => {
          this.shareLink = `https://socialjet.tech/XyxBjj?SM_name=${this.group.social}&SM_id=${this.group.id}&shareToken=${data.token}`;
          this.copyToBuffer(this.shareLink);
          this.$store.dispatch('app/setSnackBar', { text: this.$t('the_link_is_copied_to_the_clipboard') });
        });
    },
    addToRk() {
      if (this.isAuthUser) return;
      this.showFormModal = true;
    },
  },
  computed: {
    isAuthUser() {
      const auth = this.$store.getters['user/getUserInfo'];
      return !!auth;
    },
    getCurrencySymbol() {
      return this.$store.getters['app/getCurrencySymbol'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-actions-bar {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include between-elements {
    margin-bottom: 40px;
  }
  @include desktop-screen {
    justify-content: space-between;
    @include between-elements {
      margin-bottom: 0px;
    }
  }
}

.group-actions-bar__block {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  @include between-elements {
    margin-bottom: 20px;
  }
  @include tablet-screen {
    justify-content: flex-start;
    @include between-elements {
      margin-right: 12px;
      margin-bottom: 0px;
    }
  }
  &_price {
    @include between-elements {
      margin-right: 20px;
    }
  }
}

.group-actions-bar__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #66CEF1;
  border-radius: 33px;
  padding: 0px 32px;
  height: 52px;
  color: $primary-darkblue;
  cursor: pointer;
  white-space: nowrap;
  width: 300px;
  @include tablet-screen {
    margin-bottom: 0px;
    width: auto;
  }
  &_share {
    position: relative;
    padding: 16px 32px 16px 70px;
  }
  &_blue {
    background: $primary-blue;
    border: none;
    color: white;
    margin-bottom: 20px;
    @include between-elements {
      margin-right: 15px;
    }
    @include tablet-screen {
      margin-bottom: 0px;
  }
  }
}

.group-actions-bar__text {
  font-size: 14px;
  &_title-gray {
    color: #7B7B7B;
  }
  &_bold {
    font-weight: 600;
    font-size: 22px;
  }
}
.group-actions-bar__share-icon {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 51px;
  height: 52px;
}

.group-actions-bar__price-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  margin-bottom: 20px;
  margin-left: 20px;
  @include tablet-screen {
    align-items: flex-start;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}
</style>
