<template>
  <div
  ref="myGroups"
  class="my-groups">
    <NavActions
    v-if="!isEmptyGroups && !isLoadingGroups"
    :groups-data="groups"
    mode="publisher"
    :selected-groups="getSelectedGroups"
    @selectAll="selectAllGroups(!isAllSelected)" />

    <div
    v-if="!isEmptyGroups && !isLoadingGroups"
    ref="myGroupsContent"
    class="my-groups__content"
    @scroll="onScroll"
    @mousedown.left="mouseDown"
    @mouseup.left="mouseUp"
    @mouseleave="mouseLeave"
    @mousemove="mouseMove">
      <div
      v-if="!isLoadingGroups"
      ref="groupElements"
      class="my-groups__elements">
        <GroupsHeaders
        :groups-data="groups"
        :is-admin="isAdmin"
        :selected-groups="getSelectedGroups"
        :columns="columns"
        :is-all-selected="isAllSelected"
        @clickCheckbox="selectAllGroups"
        @scrollToTop="scrollToTop" />
        <Group
        v-for="group in groups"
        :key="group.name + group.id"
        :group="group"
        :group-is-checked="groupIsChecked(group)"
        :selected-groups="getSelectedGroups"
        :selected-type-of-groups="getSelectedTypeOfGroups"
        :hide-group-details="getHideGroupDetails"
        :is-admin="isAdmin"
        :is-agency="isAgency"
        :columns="columns"
        :show-group-menu="true"
        @clickCheckbox="(value) => setGroupIsChecked(value, group)" />
      </div>
    </div>

    <div
    v-if="isEmptyGroups || isLoadingGroups"
    class="my-groups__elements-empty">
      <img
      v-if="isLoadingGroups"
      class="my-groups__loader"
      src="@/assets/svg/spinner-loader.svg">
      <span
      v-else
      class="my-groups__text">
        Список каналов пуст
      </span>
    </div>

    <transition name="fade">
      <div
      v-show="scrollPosition > 1320"
      class="my-groups__up-button"
      @click="scrollToTop">
        <span class="my-groups__up-button-text">{{ $t('up') }}</span>
        <DataIcon
        name="north"
        class="my-groups__up-button-icon"
        :size="16"
        pointer />
      </div>
    </transition>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import groupsData from '@/assets/js/groupsData';

import NavActions from '@/components/MainGroups/components/NavActions/NavActions.vue';
import GroupsHeaders from '@/components/MainGroups/components/GroupsHeaders.vue';
import Group from '@/components/MainGroups/components/Group/Group.vue';

export default {
  components: {
    GroupsHeaders, NavActions, Group,
  },
  props: {
    groups: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    scrollPosition: 0,
    columns: null,
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  created() {
    this.initColumns();
  },
  methods: {
    initColumns() {
      const savedGroupsColumns = JSON.parse(localStorage.getItem('savedGroupsColumns240124'));
      if (savedGroupsColumns) {
        savedGroupsColumns.unshift({
          name: 'name',
          value: 'name',
          show: true,
          sort: 'name',
        });
      }
      this.columns = savedGroupsColumns || cloneDeep(groupsData.columns);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$refs.myGroupsContent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll() {
      const position = this.$refs.myGroupsContent.scrollTop;
      const { clientHeight } = this.$refs.myGroupsContent;

      if (position > this.scrollPosition) {
        this.$refs.myGroups.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      this.scrollPosition = position;

      if (this.$refs.myGroupsContent.scrollHeight <= clientHeight
      || this.isLastPage
      || this.isLoadingGroups) {
        return;
      }

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.myGroupsContent.scrollHeight);

      if (endScroll) {
        this.$emit('loadMyGroups');
      }
    },
    mouseDown(e) {
      if (e.target.className === 'group-name__block'
      || e.target.className === 'group-name'
      || e.target.className.includes('input')
      || e.target.className.includes('editor')) return;
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.myGroupsContent.offsetLeft;
      this.scrollLeft = this.$refs.myGroupsContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll || this.showGroupMenu) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.myGroupsContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.myGroupsContent.scrollLeft = this.scrollLeft - walk;
    },
    selectAllGroups(value) {
      if (!value) {
        const groupsToUnselected = this.getSelectedGroups.filter((item) => !this.groups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('myGroups/setSelectedGroups', groupsToUnselected);
      } else {
        const groupsToSelected = this.groups.filter((item) => !this.getSelectedGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('myGroups/setSelectedGroups', [...this.getSelectedGroups, ...groupsToSelected]);
      }
    },
    setHideGroupDetails() {
      localStorage.setItem('hideDetails', !this.getHideGroupDetails);
      this.$store.commit('groups/setHideGroupDetails', !this.getHideGroupDetails);
    },
    groupIsChecked(group) {
      return this.getSelectedGroups.length > 0
        ? this.getSelectedGroups.some((item) => (item.name === group.name && item.id === group.id))
        : false;
    },
    setGroupIsChecked(value, group) {
      if (value) {
        const newSelectedGroups = [...this.getSelectedGroups, group];
        this.$store.commit('myGroups/setSelectedGroups', newSelectedGroups);
        return;
      }
      const newSelectedGroups = this.getSelectedGroups.filter((item) => !(item.id === group.id && item.name === group.name));
      this.$store.commit('myGroups/setSelectedGroups', newSelectedGroups);
    },
  },
  computed: {
    isEmptyGroups() {
      return this.groups.length === 0 && !this.isLoadingGroups;
    },
    getSelectedGroups() {
      return this.$store.getters['myGroups/getSelectedGroups'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['myGroups/getSelectedTypeOfGroups'];
    },
    isLoadingGroups() {
      return this.$store.getters['myGroups/getLoadingGroups'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isLastPage() {
      return this.$store.getters['myGroups/getCurrentPage'] === (+this.$store.getters['myGroups/getTotalPageCounts'] + 1);
    },
    showGroupMenu() {
      return this.$store.getters['groups/getShowGroupMenu'];
    },
    isAllSelected() {
      return this.getSelectedGroups.length === this.groups.length;
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
  },
  watch: {
    groups() {
      if (this.groups.length < 15) {
        this.scrollPosition = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-groups {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  position: relative;
  margin: 20px 0px 0px;
  flex: 1;
  @include tablet-screen {
    margin: 20px;
  }
}

.my-groups__content {
  background: white;
  z-index: 1;
  overflow: auto;
  overflow-x: scroll;
  width: 100%;
  height: calc(100vh - 250px);
}

.my-groups__groups-empty {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

.my-groups__text {
  color: $primary-gray;
  font-size: 16px;
}

.my-groups__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.my-groups__up-button {
  display: none;
  @include desktop-screen {
    position: absolute;
    padding: 5px 20px;
    bottom: 110px;
    right: 40px;
    color: $primary-white;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    background: $primary-blue;
    align-items: center;
  }
}

.my-groups__elements-empty {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

.my-groups__loader {
  height: 150px;
  animation: pulse 1.2s ease-in-out infinite;
  z-index: 10;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(.98);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0.3;
  }
}
</style>
