<template>
  <div class="compilation-info">
    <div class="compilation-info__title-container">
      <div class="compilation-info__main">
        <div class="compilation-info__title-block">
          <span class="compilation-info__title">{{ !getIsLoadingCompilation ? getCompilationName : 'Загрузка...' }}</span>
          <DataSelect
          v-if="(isAdmin || isManager) && !getIsLoadingCompilation"
          v-model="selectedRealisationManagers"
          :options="realisationsManagersList"
          class="compilation-info__select"
          select-type="multi"
          with-search
          placeholder="Выбрать ответственного"
          @input="loadWithResponsibleUsers" />
        </div>
        <div
        v-if="!getIsLoadingCompilation"
        class="compilation-info__title-block">
          <DataButton
          v-if="!isNoauthMode"
          text="Добавить каналы"
          :height="30"
          @handler="$emit('addGroupsToCompilation')" />
          <transition
          v-if="!isNoauthMode"
          name="fade">
            <DataButton
            v-if="getSelectedGroups.length"
            :height="30"
            text="Удалить каналы"
            type="danger"
            @handler="deleteSelectedGroupsFromCompilation" />
          </transition>
        </div>
        <div
        v-if="!getIsLoadingCompilation"
        class="compilation-info__date-container">
          <span
          v-if="createdDate"
          class="compilation-info__date-text">{{ createdDate }}</span>
        </div>
        <!-- Статистика и дата -->
        <div
        v-if="!getIsLoadingCompilation"
        class="compilation-info__container">
          <div
          v-if="getCurrentCompilationSummary"
          class="compilation-info__stats">
            <div
            class="compilation-info__stat-item"
            style="padding-left: 0px">
              <img src="@/assets/svg/compilation/FileText-black.png">
              <span class="compilation-info__stat-text">Кол-во каналов</span>
              <span class="compilation-info__stat-text compilation-info__stat-text_stat">
                {{ getCurrentCompilationSummary?.countSelectedGroups
              ? setNumberSpaces(Math.round(getCurrentCompilationSummary.countSelectedGroups))
              : '-'
                }}
              </span>
            </div>
            <div class="compilation-info__stat-item">
              <img src="@/assets/svg/compilation/UsersThree.png">
              <span class="compilation-info__stat-text">Подписчиков</span>
              <span class="compilation-info__stat-text compilation-info__stat-text_stat">
                {{ getCurrentCompilationSummary?.sumSubscribersCount
              ? setNumberSpaces(Math.round(getCurrentCompilationSummary.sumSubscribersCount))
              : '-'
                }}
              </span>
            </div>
            <div class="compilation-info__stat-item">
              <img src="@/assets/svg/compilation/Eye.png">
              <span class="compilation-info__stat-text">Просмотров</span>
              <span class="compilation-info__stat-text compilation-info__stat-text_stat">
                {{ getCurrentCompilationSummary?.sumViewsLast24hCount
              ? setNumberSpaces(Math.round(getCurrentCompilationSummary.sumViewsLast24hCount))
              : '-'
                }}
              </span>
            </div>
            <div class="compilation-info__stat-item">
              <img src="@/assets/svg/compilation/Eye.png">
              <span class="compilation-info__stat-text">CPM</span>
              <span class="compilation-info__stat-text compilation-info__stat-text_stat">
                {{ getCurrentCompilationSummary?.averageCpm
              ? setNumberSpaces(Math.round(getCurrentCompilationSummary.averageCpm))
              : '-'
                }}
              </span>
            </div>
            <div
            class="compilation-info__stat-item"
            style="border: none">
              <img src="@/assets/svg/compilation/Pulse.png">
              <span class="compilation-info__stat-text">Сумма</span>
              <span class="compilation-info__stat-text compilation-info__stat-text_stat">
                {{ getCurrentCompilationSummary?.sumPostPriceSell
              ? setNumberSpaces(Math.round(getCurrentCompilationSummary.sumPostPriceSell))
                : '-' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <PriceSwitcher
      v-if="!getIsLoadingCompilation"
      is-compilation />
      <div
      v-if="!getIsLoadingCompilation"
      class="compilation-info__title-actions">
        <!-- Share -->
        <div
        v-if="!isNoauthMode"
        class="compilation-info__share-container pointer"
        @click="shareCompilation">
          <div
          class="compilation-info__share-button">
            <DataIcon
            name="share"
            :size="14"
            class="compilation-info__share-icon" />
          </div>
          <span class="compilation-info__text">
            Отправить подборку
          </span>
        </div>

        <!-- Экспорт -->
        <div
        class="compilation-info__share-container pointer"
        @click="exportAllCompilation">
          <div class="compilation-info__share-button">
            <DataIcon
            name="download"
            :size="18"
            class="compilation-info__share-icon" />
          </div>
          <span class="compilation-info__text">
            Экспорт подборки
          </span>
        </div>

        <!-- Наценка -->
        <div
        v-if="isAdmin"
        class="compilation-info__set-margin pointer"
        @click="openSetMargin = true">
          <div class="compilation-info__share-container">
            <div class="compilation-info__share-button">
              <DataIcon
              name="margin"
              :size="18"
              class="compilation-info__share-icon" />
            </div>
            <span class="compilation-info__text">
              Изменить наценку
            </span>
          </div>
          <div
          v-if="openSetMargin"
          v-click-outside="() => { openSetMargin = false }"
          class="compilation-info__new-value-block">
            <div class="compilation-info__new-value-container">
              <span class="compilation-info__value-text">
                Новая наценка:
              </span>
              <DataInput
              v-model="newValueMargin"
              class="compilation-info__new-value-input" />
              <DataButton
              icon="done"
              width="40"
              height="40"
              @handler="setMargin" />
            </div>
            <span
            v-if="newValueMarginError && isInvalidNewMarginValue"
            class="compilation-info__value-text compilation-info__value-text_invalid">Укажите наценку от 1 до 100</span>
          </div>
        </div>

        <!-- Сохранение к себе -->
        <div
        class="compilation-info__share-container pointer"
        @click="openSaveForYourselfModal">
          <div class="compilation-info__share-button">
            <DataIcon
            name="add_circle"
            :size="18"
            class="compilation-info__share-icon" />
          </div>
          <span class="compilation-info__text">
            Сохранить к себе
          </span>
          <DataModal
          :show-modal="showSaveForYourselfModal"
          @close="showSaveForYourselfModal = false">
            <template #modal-content>
              <div class="compilation-info__save-yourself">
                <span class="compilation-info__stat-text">Введите название для сохраняемой подборки (не обязательно)</span>
                <DataInput v-model="saveCompilationName" />
                <DataButton
                text="Сохранить"
                @handler="saveForYourself" />
              </div>
            </template>
          </DataModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PriceSwitcher from '../../CatalogScreen/components/GroupsFilter/components/PriceSwitcher.vue';

export default {
  name: 'CompilationInfo',
  components: { PriceSwitcher },
  props: {
    isNoauthMode: {
      type: Boolean,
      default: null,
    },
    getIsLoadingCompilation: {
      type: Boolean,
      default: null,
    },
    isRequest: {
      type: Boolean,
      default: null,
    },
    compilationName: {
      type: String,
      default: null,
    },
    realisationsManagers: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    selectedRealisationManagers: [],
    openSetMargin: false,
    newValueMargin: '',
    newValueMarginError: false,
    showSaveForYourselfModal: false,
    saveCompilationName: '',
  }),
  methods: {
    deleteSelectedGroupsFromCompilation() {
      const groupsLength = this.getSelectedGroups.length;
      this.$store.dispatch('compilations/removeGroupsFromCompilation', { groups: this.getSelectedGroups, id: this.getCompilationInfo.id })
        .then(() => {
          if (groupsLength > 1) {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('groups_have_been_removed_from_the_collection') });
          } else {
            this.$store.dispatch('app/setSnackBar', { text: this.$t('group_have_been_removed_from_the_collection') });
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: this.$t('an_error_occurred_when_changing_the_selection_reload_the_page_and_try_again'),
              error: true,
            });
        });
    },
    shareCompilation() {
      this.$store.dispatch('compilations/shareCompilation', this.getCompilationInfo.id)
        .then((data) => {
          const link = `https://socialjet.tech/kn1XZb?id=${this.getCompilationInfo.id}&shareToken=${data.token}`;
          const isCopy = this.copyToBuffer(link);
          if (isCopy) {
            this.$store.dispatch('app/setSnackBar', { text: 'Ссылка скопирована в буфер обмена' });
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar',
            {
              text: 'При создании ссылки произошла ошибка. Попробуйте позже',
              error: true,
            });
        });
    },
    exportAllCompilation() {
      const compilation = { name: this.getCompilationInfo.name, id: this.getCompilationInfo.id, isFolder: false };
      const modalParams = { name: 'ExportGroupsModal', props: { compilation } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
    loadWithResponsibleUsers() {
      const ids = this.selectedRealisationManagers.map((item) => item.value);
      const params = {
        id: this.getCompilationInfo.id,
        reset: true,
        responsibleUserIds: ids.filter((item) => item !== 'novalue'),
        withoutResponsibleUser: ids.includes('novalue') ? true : null,
      };
      this.$emit('loadWithResponsibleUsers', params);
    },
    setMargin() {
      if (this.isInvalidNewMarginValue) {
        this.newValueMarginError = true;
        return;
      }
      this.newValueMarginError = false;
      const params = {
        compilationId: String(this.getCompilationInfo.id),
        positions: [],
        value: this.newValueMargin,
        type: 'margin',
      };
      this.$store.dispatch('compilations/changeCollectionPrice', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Наценка подборки изменена' });
          window.location.reload();
        })
        .catch(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Произошла ошибка. Попробуйте позже', error: true });
        })
        .finally(() => {
          this.openSetMargin = false;
        });
    },
    saveForYourself() {
      const params = { name: this.saveCompilationName, compilationId: this.getCompilationInfo.id };
      this.$store.dispatch('compilations/saveCompilationForYourSelf', params)
        .then(() => {
          this.$store.dispatch('app/setSnackBar', { text: 'Подборка сохранена' });
        })
        .finally(() => {
          this.showSaveForYourselfModal = false;
        });
    },
    openSaveForYourselfModal() {
      this.saveCompilationName = this.getName();
      this.showSaveForYourselfModal = true;
    },
    getName() {
      if (this.isRequest) {
        return this.compilationName;
      }
      if (this.getCompilationInfo.name && this.getCompilationInfo.name !== 'noname') {
        return this.getCompilationInfo.name;
      }
      if (!this.compilationName || this.getCompilationInfo.name === 'noname') {
        return this.getCompilationInfo.id;
      }
      const name = this.compilationName || this.getCompilationInfo.name;
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
  computed: {
    getCompilationName() {
      const name = this.getName();

      if (this.isRequest) {
        return `Рекламная кампания: ${name}`;
      }
      if (this.getCompilationInfo.name && this.getCompilationInfo.name !== 'noname') {
        return `Подборка: ${name}`;
      }
      if (!this.compilationName || this.getCompilationInfo.name === 'noname') {
        return `Подборка №${name}`;
      }

      return `Подборка: ${this.compilationName || this.getCompilationInfo.name}`;
    },
    createdDate() {
      const date = this.getCompilationInfo.createdAt?.date || null;
      return date ? this.moment(date).format('LLL') : null;
    },
    getSelectedGroups() {
      return this.$store.getters['compilations/getCompilationsSelectedList'];
    },
    getCurrentCompilationSummary() {
      return this.$store.getters['compilations/getCurrentCompilationSummary'];
    },
    getCompilationInfo() {
      return this.$store.getters['compilations/getCurrentCompilationInfo'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    realisationsManagersList() {
      const managers = this.$store.getters['user/getRealisationsManagers'] || [];
      const novariant = { name: 'Без ответственного', value: 'novalue' };
      const list = managers.filter((item) => item.name && item.id).map((item) => ({ name: item.name, value: item.id }));
      list.unshift(novariant);
      return list;
    },
    isInvalidNewMarginValue() {
      if (!this.isAdmin) return true;
      return !this.newValueMargin || Number(this.newValueMargin) > 100 || Number(this.newValueMargin) < 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.compilation-info {
  border-radius: 16px;
  padding: 20px;
  border: 1px solid #D8F2FA;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.compilation-info__title {
  font-size: 20px;
  color: $primary-gray;
  font-weight: 600;
  max-width: 600px;
}

.compilation-info__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include desktop-screen {
    justify-content: space-between;
    flex-direction: row;
  }
}

.compilation-info__title-actions {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  margin-left: 20px;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.compilation-info__container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
  @include tablet-screen {
    flex-wrap: nowrap;
  }
}

.compilation-info__stats {
  display: flex;
  padding-right: 20px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  @include between-elements {
    margin-right: 30px;
  }
  @include tablet-screen {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.compilation-info__stat-item {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  @include between-elements {
    margin-right: 10px;
  }
}

.compilation-info__stat-text {
  color: $primary-gray;
  font-size: 16px;
  &_stat {
    color: $primary-blue;
    font-weight: 600;
  }
}

.compilation-info__share-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compilation-info__share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: $primary-blue;
  margin-right: 10px;
  flex-shrink: 0;
}

.compilation-info__text {
  color: $primary-gray;
  font-size: 13px;
}

.compilation-info__share-icon {
  color: $primary-white;
}

.compilation-info__date-text {
  color: $primary-gray;
  font-size: 13px;
  white-space: nowrap;
  text-align: center;
}

.compilation-info__date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.compilation-info__title-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 20px;
  @include between-elements {
    margin-right: 20px;
  }
}

.compilation-info__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.compilation-info__main {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.compilation-info__select {
  width: 300px;
  max-width: 300px;
}

.compilation-info__set-margin {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.compilation-info__new-value-block {
  position: absolute;
  top: 35px;
  right: 0px;
  background-color: white;
  width: 290px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  border-radius: 12px;
  padding: 10px 20px;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.compilation-info__new-value-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.compilation-info__new-value-input {
  width: 75px;
}

.compilation-info__value-text {
  color: $primary-gray;
  font-size: 14px;
  white-space: nowrap;
  &_invalid {
    color: $primary-red;
    margin-top: 5px;
  }
}

.compilation-info__save-yourself {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
  text-align: center;
  @include between-elements {
    margin-bottom: 20px;
  }
}
</style>
