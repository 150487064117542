<template>
  <div
  class="group-views">
    <div
    :class="isAdmin && 'pointer'"
    class="group-views__content"
    @click="openEditor">
      <!-- Просмотры MAVE -->
      <div
      v-if="getSelectedTypeOfGroups === 'mave'"
      class="group-views__views">
        <div class="group-views__element">
          <span
          class="group-views__text"
          :class="!group.listeningStatistic || !group.listeningStatistic.averagePerWeek && 'group-views__text_light'">
            {{ getLabelForViewsCount(group.listeningStatistic && group.listeningStatistic.averagePerWeek ? group.listeningStatistic.averagePerWeek : null) }}
          </span>
          <span class="group-views__text group-views__text_light">{{ $t('per7days_2') }}</span>
        </div>
        <div
        class="group-views__element">
          <span
          class="group-views__text"
          :class="!group.listeningStatistic || !group.listeningStatistic.averagePerMonth && 'group-views__text_light'">
            {{ getLabelForViewsCount(group.listeningStatistic && group.listeningStatistic.averagePerMonth ? group.listeningStatistic.averagePerMonth : null) }}
          </span>
          <span class="group-views__text group-views__text_light">{{ $t('per30days_2') }}</span>
        </div>
      </div>

      <!-- Просмотры остальное -->
      <div
      v-else
      class="group-views__views">
        <div class="group-views__element">
          <span
          class="group-views__text"
          :class="!group.viewsStatistic || !group.viewsStatistic.countLast24h && 'group-views__text_light'">{{
            getLabelForViewsCount(group.viewsStatistic && group.viewsStatistic.countLast24h ? group.viewsStatistic.countLast24h : null)
          }}</span>
          <span class="group-views__text group-views__text_light">{{ $t('per24') }}</span>
        </div>
        <div
        v-if="!hideDetails"
        class="group-views__element">
          <span
          class="group-views__text"
          :class="!group.viewsStatistic || !group.viewsStatistic.countLast48h && 'group-views__text_light'">
            {{ getLabelForViewsCount(group.viewsStatistic && group.viewsStatistic.countLast48h ? group.viewsStatistic.countLast48h : null) }}
          </span>
          <span class="group-views__text group-views__text_light">{{ $t('per48') }}</span>
        </div>
        <div
        v-if="!hideDetails"
        class="group-views__element">
          <span
          class="group-views__text"
          :class="!group.viewsStatistic || !group.viewsStatistic.countLast72h && 'group-views__text_light'">
            {{ getLabelForViewsCount(group.viewsStatistic && group.viewsStatistic.countLast72h ? group.viewsStatistic.countLast72h : null) }}
          </span>
          <span class="group-views__text group-views__text_light">{{ $t('per72') }}</span>
        </div>
      </div>

      <!-- ER -->
      <div
      v-if="getSelectedTypeOfGroups !== 'mave'"
      class="group-views__element">
        <span
        class="group-views__text"
        :class="[ !group.er && 'group-views__text_light']">
          {{ group.er ? group.er + '%' : '—' }}
        </span>
        <span class="group-views__text group-views__text_light">
          {{ $t('er') }}
        </span>
      </div>
    </div>

    <!-- Редактор -->
    <transition name="fade">
      <div
      v-if="showEditor"
      v-click-outside="onClickOutside">
        <!-- MAVE EDITOR -->
        <div
        v-if="getSelectedTypeOfGroups === 'mave'"
        class="group-views__editor">
          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              Аудитория за неделю
            </span>
            <DataInput
            v-model="averagePerWeek"
            placeholder="Просмотры за неделю" />
          </div>

          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              Аудитория за месяц
            </span>
            <DataInput
            v-model="averagePerMonth"
            placeholder="Просмотры за месяц" />
          </div>

          <DataButton
          :text="$t('save')"
          type="text"
          @handler="saveMaveChanges" />
        </div>

        <!-- Остальные -->
        <div
        v-else
        class="group-views__editor">
          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              Просмотры за 24ч
            </span>
            <DataInput
            v-model="countLast24hValue"
            placeholder="Просмотры за 24ч" />
          </div>
          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              Просмотры за 48ч
            </span>
            <DataInput
            v-model="countLast48hValue"
            placeholder="Просмотры за 48ч" />
          </div>
          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              Просмотры за 72ч
            </span>
            <DataInput
            v-model="countLast72hValue"
            placeholder="Просмотры за 72ч" />
          </div>
          <div class="group-views__editor-element">
            <span class="group-views__text_light">
              ER
            </span>
            <DataInput
            v-model="erValue"
            :placeholder="$t('Specify the er')" />
          </div>

          <DataButton
          :text="$t('save')"
          type="text"
          @handler="saveChanges" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GroupViews',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditor: false,
    countLast24hValue: null,
    countLast48hValue: null,
    countLast72hValue: null,
    erValue: null,
    // mave
    averagePerWeek: null,
    averagePerMonth: null,
  }),
  methods: {
    getLabelForViewsCount(count) {
      if (count === null || count === undefined) {
        return '—';
      }
      count = Math.abs(count);

      if (count > 999 && count < 100000) {
        return `${parseFloat((count / 1000).toFixed(1))}k`;
      }
      if (count >= 100000 && count < 1000000) {
        return `${(count / 1000).toFixed(0)}k`;
      }
      if (count >= 1000000) {
        return `${parseFloat((count / 1000000).toFixed(1))}m`;
      }
      return count;
    },
    openEditor() {
      if (!this.isAdmin || this.disableEditor) return;

      if (this.getSelectedTypeOfGroups === 'mave') {
        this.averagePerWeek = this.group.listeningStatistic?.averagePerWeek || null;
        this.averagePerMonth = this.group.listeningStatistic?.averagePerMonth || null;
      } else {
        this.countLast24hValue = this.group.viewsStatistic?.countLast24h || null;
        this.countLast48hValue = this.group.viewsStatistic?.countLast48h || null;
        this.countLast72hValue = this.group.viewsStatistic?.countLast72h || null;
      }

      this.erValue = this.group.er;
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.countLast24hValue = null;
      this.countLast48hValue = null;
      this.countLast72hValue = null;
      this.averagePerWeek = null;
      this.averagePerMonth = null;
    },
    onClickOutside() {
      this.closeEditor();
    },
    saveMaveChanges() {
      const newData = {
        listeningStatistic: {
          averagePerWeek: this.averagePerWeek === '' ? null : this.averagePerWeek,
          averagePerMonth: this.averagePerMonth === '' ? null : this.averagePerMonth,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.listeningStatistic.averagePerWeek = this.averagePerWeek === '' ? null : this.averagePerWeek;
          this.group.listeningStatistic.averagePerMonth = this.averagePerMonth === '' ? null : this.averagePerMonth;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
    saveChanges() {
      const newData = {
        er: this.erValue === '' ? null : +this.erValue,
        viewsStatistic: {
          countLast24h: this.countLast24hValue === '' ? null : this.countLast24hValue,
          countLast48h: this.countLast48hValue === '' ? null : this.countLast48hValue,
          countLast72h: this.countLast72hValue === '' ? null : this.countLast72hValue,
        },
      };

      this.showEditor = false;

      this.$store.dispatch('groups/groupUpdate', { id: this.group.id, social: this.group.social, newData })
        .then(() => {
          this.group.viewsStatistic.countLast24h = this.countLast24hValue === '' ? null : this.countLast24hValue;
          this.group.viewsStatistic.countLast48h = this.countLast48hValue === '' ? null : this.countLast48hValue;
          this.group.viewsStatistic.countLast72h = this.countLast72hValue === '' ? null : this.countLast72hValue;
          this.group.er = this.erValue === '' ? null : this.erValue;
          this.$store.dispatch('app/setSnackBar', { text: this.$t('Data updated'), error: false });
        });
    },
  },
  computed: {
    isEmptyData() {
      if (!this.group.viewsStatistic) return true;
      return !this.group.viewsStatistic?.countLast24h && !this.group.viewsStatistic?.countLast48h && !this.group.viewsStatistic?.countLast72h;
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
  },
};
</script>

<style lang="scss" scoped>
.group-views {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  width: 180px;
  height: 50px;
}

.group-views__views {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-views__content {
  display: flex;
  flex-direction: column;
  position: relative;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.group-views__element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.group-views__text {
  font-size: 14px;
  margin-right: 5px;
  color: $primary-gray;
  &_light {
    font-size: 12px;
    color: $primary-lightgray;
  }
  &_small {
    font-size: 12px;
  }
  &_error {
    color: $primary-red;
  }
}

.group-views__editor {
  position: absolute;
  top: 0px;
  left: 30px;
  min-width: 280px;
  border-radius: 12px;
  padding: 20px 20px 0px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include between-elements {
    margin-bottom: 10px;
  }
}

.group-views__editor-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include between-elements {
    margin-bottom: 5px;
  }
}
</style>
