<template>
  <div class="group-menu">
    <div class="group-menu__elements">
      <!-- Выбор -->
      <div
      v-tooltip="`${$t('select')}`"
      class="group-menu__element pointer"
      @click="$emit('update:checked', !checked)">
        <DataIcon
        :name="checked ? 'radio_button_checked' : 'radio_button_unchecked'"
        :size="16"
        class="group-menu__text_light"
        :class="checked && 'group-menu__text_check'" />
      </div>

      <!-- Добавление в избранное -->
      <div
      v-if="mode !== 'noauth'"
      v-tooltip="isFavoriteGroup ? $t('remove_from_favorites') : $t('add_to_favorites')"
      class="group-menu__element pointer"
      @click="toggleInFavorite">
        <DataIcon
        :name="isFavoriteGroup ? 'favorite_border' : 'favorite_border'"
        :size="16"
        :class="isFavoriteGroup && 'group-menu__text_active'"
        class="group-menu__text_light" />
      </div>

      <!-- Добавление в подборку -->
      <div
      v-if="mode !== 'noauth'"
      v-tooltip="'Добавить канал в подборку'"
      class="group-menu__element pointer"
      @click="addGroupInCompilation">
        <DataIcon
        name="add"
        :size="16"
        class="group-menu__text_light" />
      </div>

      <!-- Редактирование канала -->
      <div
      v-if="isAdmin"
      v-tooltip="$t('Edit a channel')"
      class="group-menu__element pointer"
      @click="$emit('openEditNameModal')">
        <DataIcon
        name="edit"
        :size="16"
        class="group-menu__text_light" />
      </div>

      <!-- Копирование ссылки -->
      <div
      v-tooltip="$t('copy_link')"
      class="group-menu__element pointer"
      @click="copySocialLink">
        <DataIcon
        name="content_copy"
        :size="16"
        class="group-menu__text_light" />
      </div>

      <!-- Удаление канала -->
      <div
      v-if="isAdmin && !isCompilation"
      v-tooltip="$t('delete_channel')"
      class="group-menu__element pointer"
      @click="$emit('openDeleteModal')">
        <DataIcon
        name="clear"
        :size="16"
        class="group-menu__text_light" />
      </div>

      <!-- Добавление в бан -->
      <div
      v-if="isAdmin && !isCompilation"
      v-tooltip="isBannedGroup ? $t('Restore channel') : 'Отправить в бан'"
      class="group-menu__element pointer"
      @click="toggleBan">
        <DataIcon
        :name="isBannedGroup ? 'restore' : 'do_disturb_alt'"
        :size="16"
        class="group-menu__text_light group-menu__text_active" />
      </div>

      <!-- Добавление в ЧС -->
      <div
      v-if="!isCompilation"
      v-tooltip="'Отправить в ЧС'"
      class="group-menu__element pointer"
      @click="addToBlackList">
        <DataIcon
        name="cancel"
        :size="16"
        class="group-menu__text_light group-menu__text_active" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupMenu',
  props: {
    hideDetails: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: null,
    },
    checked: {
      type: Boolean,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    disableEditor: {
      type: Boolean,
      default: false,
    },
    isCompilation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
  }),
  methods: {
    toggleInFavorite() {
      const groups = [this.group];

      if (this.isFavoriteGroup) {
        this.$store.dispatch('favorites/removeGroupsFromFavorites', groups)
          .then(() => { this.$store.dispatch('app/setSnackBar', { text: this.$t('channel_removed_from_favorites') }); })
          .catch(() => { this.$store.dispatch('app/setSnackBar', { text: this.$t('Error'), error: true }); });
        return;
      }

      this.$store.dispatch('favorites/addGroupsToFavorites', groups)
        .then(() => { this.$store.dispatch('app/setSnackBar', { text: this.$t('channel_added_to_favorites') }); })
        .catch(() => { this.$store.dispatch('app/setSnackBar', { text: this.$t('Error'), error: true }); });
    },
    copySocialLink() {
      this.copyToBuffer(this.group.url);
      this.$store.dispatch('app/setSnackBar', { text: this.$t('the_link_is_copied_to_the_clipboard') });
    },
    toggleBan() {
      if (this.isBannedGroup) {
        this.$emit('restoreGroup');
      }
      this.$emit('addToBanned');
    },
    addToBlackList() {
      this.$emit('addToBlackList');
    },
    addGroupInCompilation() {
      this.$store.commit('app/setNewGroupsToCompilation', [this.group]);
      const modalParams = { name: 'SaveCollectionModal', props: { addFromMenu: true } };
      this.$store.commit('app/setCurrentModal', modalParams);
    },
  },
  computed: {
    isFavoriteGroup() {
      return this.getFavoriteGroups.some((item) => (item.name === this.group.name && item.id === this.group.id));
    },
    getFavoriteGroups() {
      return this.$store.getters['favorites/getFavoriteGroupsData'];
    },
    isBannedGroup() {
      return this.group.status === 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-menu {
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 40%);
  height: 46px;
  padding: 0px 20px;
  background: white;
  border-radius: 8px;
}

.group-menu__elements {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include between-elements {
    margin-right: 10px;
  }
}

.group-menu__element {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 1px solid rgb(228, 228, 228);
}

.group-menu__text {
  font-size: 14px;
  color: $primary-gray;
  text-align: center;
  &_small {
    font-size: 12px;
  }
  &_light {
    color: $primary-lightgray;
    text-align: center;
  }
  &_active {
    color: $primary-red;
  }
  &_check {
    text-align: center;
    color: $primary-blue;
  }
}

.menu {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  height: 200px;
  display: flex;
  z-index: 5;
  background: white;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

</style>
