const getBaseUrl = () => {
  const url = window.location.href;

  if (url.includes('stage-datafront')) {
    return 'https://stage-datafront.socialjet.pro';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'https://data.socialjet.pro';
  }

  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8080';
  }

  return 'https://data.socialjet.pro';
};

export default getBaseUrl;
