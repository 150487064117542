<template>
  <div
  ref="numeric-filter"
  class="numeric-filter"
  @click="openMenu">
    <div class="numeric-filter__title">
      <span class="numeric-filter__text numeric-filter__text_second">
        {{ title }}
      </span>
      <transition name="fade">
        <div
        v-if="isNotEmptyValues"
        class="numeric-filter__title-active" />
      </transition>
    </div>
    <span
    v-if="getMinValue"
    class="numeric-filter__text"
    :class="isNotEmptyValues && 'numeric-filter__text_blue'">
      {{ getMinValue }}
    </span>
    <span
    v-if="getMaxValue"
    class="numeric-filter__text"
    :class="isNotEmptyValues && 'numeric-filter__text_blue'">
      {{ getMaxValue }}
    </span>
    <transition name="fade">
      <div
      v-if="isOpen"
      v-click-outside="onClickOutside"
      class="numeric-filter__menu"
      :style="{
        width: menuWidth ? menuWidth + 'px' : '',
        top: `${top}px`,
        left: `${left}px`,
      }">
        <div class="numeric-filter__menu-title">
          <span class="numeric-filter__text">
            {{ title }}
          </span>
          <div
          v-if="type"
          class="numeric-filter__menu-types">
            <div
            v-for="option in getSearchTypes"
            :key="option.value"
            class="numeric-filter__menu-type">
              <label class="numeric-filter__menu-type">
                <input
                v-model="currentSearchType"
                :value="option.value"
                type="radio"
                @change="$emit('update:search-type', currentSearchType)">
                <span class="numeric-filter__text numeric-filter__text_second">
                  {{ $t(option.text) }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <DataRangeSlider
        :value="rangeValues"
        :from-max="0"
        :to-max="rangeMax"
        :disabled="disabled"
        class="numeric-filter__slider"
        @change="changeRangeValue" />

        <div class="numeric-filter__input-block">
          <span class="numeric-filter__text numeric-filter__text_input">{{ $t('fr') }}</span>
          <input
          id="input1"
          v-model="inputMin"
          type="number"
          :disabled="disabled"
          placeholder="0"
          class="numeric-filter__input"
          @input="changeMinValue(inputMin)">
        </div>
        <div class="numeric-filter__input-block">
          <span class="numeric-filter__text numeric-filter__text_input">{{ $t('to') }}</span>
          <input
          id="input2"
          v-model="inputMax"
          type="number"
          :disabled="disabled"
          :placeholder="rangeMax"
          class="numeric-filter__input"
          @input="changeMaxValue(inputMax)">
        </div>
        <div
        v-if="withInterval"
        class="numeric-filter__intervals">
          <span class="numeric-filter__text">
            {{ $t('set_interval') }}
          </span>
          <div
          v-for="interval in getIntervals"
          :key="interval.name"
          class="numeric-filter__interval">
            <label class="numeric-filter__interval">
              <input
              v-model="currentInterval"
              type="radio"
              :value="interval"
              @change="setInterval(interval)">
              <span
              v-if="interval.text"
              class="numeric-filter__text numeric-filter__text_second">
                {{ $t(interval.text) }}
              </span>
            </label>
          </div>
        </div>
        <span
        class="numeric-filter__text numeric-filter__text_second numeric-filter__text_reset"
        @click="resetAll">
          {{ $t('reset_all') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import groupsData from '@/assets/js/groupsData';

export default {
  name: 'NumericFilter',
  props: {
    /* заголовок фильтра */
    title: {
      type: String,
      default: null,
    },
    /* максимальное возможное значение для ползунка (из aggregate values) */
    toMax: {
      type: [String, Number],
      default: null,
    },
    /* дефолтное максимальное значение для ползунка */
    toMaxDef: {
      type: Number,
      default: 1000000,
    },
    /* минимальное значение фильтра */
    min: {
      type: [String, Number],
      default: null,
    },
    /* максимальное значение фильтра */
    max: {
      type: [String, Number],
      default: null,
    },
    /* триггер для очистки данных компонента, если сброс произошел вне компонента */
    reset: {
      type: Boolean,
      default: false,
    },
    /* disable приложения */
    disabled: {
      type: Boolean,
      default: false,
    },
    /* кастомный плейсхолдер */
    placeholder: {
      type: String,
      default: null,
    },
    /* активирует блок с интервалом */
    withInterval: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    searchType: {
      type: String,
      default: null,
    },
    /* кастомная ширина блока меню */
    menuWidth: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    debounceUpdate: null,
    isOpen: false,
    preventClickOutside: false,
    intervalsSubscribers: [
      {
        text: 'Nano 1k-10k',
        min: 1000,
        max: 10000,
      },
      {
        text: 'Micro 10k-50k',
        min: 10000,
        max: 50000,
      },
      {
        text: 'Middle 50k-500k',
        min: 50000,
        max: 500000,
      },
      {
        text: 'Macro 500k-1 mil',
        min: 500000,
        max: 1000000,
      },
      {
        text: 'Mega 1 mil +',
        min: 1000000,
        max: 0,
      },
    ],
    intervalsViews: [
      {
        text: '0-1k',
        min: 0,
        max: 1000,
      },
      {
        text: '1k-2k',
        min: 1000,
        max: 2000,
      },
      {
        text: '2k-3k',
        min: 2000,
        max: 3000,
      },
      {
        text: '3k-5k',
        min: 3000,
        max: 5000,
      },
      {
        text: '5k-10k',
        min: 5000,
        max: 10000,
      },
      {
        text: '10k-20k',
        min: 10000,
        max: 20000,
      },
      {
        text: '20k-50k',
        min: 20000,
        max: 50000,
      },
      {
        text: '50k +',
        min: 50000,
        max: 0,
      },
    ],
    intervalsPrice: [
      {
        text: '0-5k',
        min: 0,
        max: 5000,
      },
      {
        text: '5k-10k',
        min: 5000,
        max: 10000,
      },
      {
        text: '10k-20k',
        min: 10000,
        max: 20000,
      },
      {
        text: '20k-50k',
        min: 20000,
        max: 50000,
      },
      {
        text: '50k-100k',
        min: 50000,
        max: 100000,
      },
      {
        text: '100k +',
        min: 100000,
        max: 0,
      },
    ],
    rangeValues: null,
    rangeMax: null,
    currentInterval: null,
    currentSearchType: null,
    inputMin: null,
    inputMax: null,
    top: 0,
    left: 0,
  }),
  created() {
    this.initial();
    this.debounceUpdate = debounce((key, value) => {
      this.$emit(key, value);
    }, 500);
  },
  methods: {
    initial() {
      // устанавливаем корректные значения и максимальное значение для ползунка
      this.rangeMax = this.toMax ? Math.ceil(+this.toMax) : this.toMaxDef;
      const minValue = this.min ? Math.floor(this.min) : 0;
      const maxValue = this.max ? Math.floor(this.max) : this.rangeMax;

      // проверка при инициализации для установки корректного ползунка
      if (minValue > maxValue || maxValue > this.rangeMax || this.minValue < 0 || this.maxValue < 0) {
        this.rangeValues = [0, this.rangeMax];
      } else {
        this.rangeValues = [minValue, maxValue];
      }

      // инпуты
      this.inputMin = minValue === 0 ? null : minValue;
      this.inputMax = maxValue === this.rangeMax ? null : maxValue;

      // interval
      this.currentInterval = null;
      this.currentSearchType = this.searchType;
    },
    resetAll() {
      this.currentInterval = null;
      this.currentSearchType = this.type ? this.getSearchTypes[0].value : null;
      this.$emit('update:search-type', this.currentSearchType);
      //
      this.$emit('update:min', null);
      this.$emit('update:max', null);
      this.rangeValues = [0, this.rangeMax];
      this.inputMin = null;
      this.inputMax = null;
    },
    setInterval(interval) {
      this.rangeValues = [interval.min, interval.max === 0 ? this.rangeMax : interval.max];
      this.inputMin = interval.min;
      this.inputMax = interval.max === 0 ? this.rangeMax : interval.max;
      this.$emit('update:min', this.inputMin);
      this.$emit('update:max', this.inputMax);
    },
    setSearchType(searchType) {
      this.searchType = searchType.value;
    },
    onClickOutside() {
      if (this.preventClickOutside) {
        this.preventClickOutside = false;
        return;
      }
      window.removeEventListener('mousedown', this.onMouseDown);
      this.isOpen = false;
    },
    onMouseDown(mouseEvent) {
      const isSlider = mouseEvent.target.className === 'data-range-slider__dot';
      this.preventClickOutside = mouseEvent.target.id === 'input1' || mouseEvent.target.id === 'input2' || isSlider;
    },
    changeRangeValue(values) {
      this.rangeValues = [...values];
      this.inputMin = this.rangeValues[0] === 0 ? null : this.rangeValues[0];
      this.inputMax = this.rangeValues[1] === this.rangeMax ? null : this.rangeValues[1];
      this.$emit('update:min', this.rangeValues[0] === 0 ? null : this.rangeValues[0]);
      this.$emit('update:max', this.rangeValues[1] === this.rangeMax ? null : this.rangeValues[1]);
      // intervals
      this.currentInterval = null;
    },
    changeMinValue() {
      this.debounceUpdate('update:min', this.inputMin);
    },
    changeMaxValue() {
      this.debounceUpdate('update:max', this.inputMax);
    },
    openMenu() {
      // слушаем событие клика на стороннем элементе
      // чтобы v-click-outside не срабатывал при выделении инпута
      this.top = this.$refs['numeric-filter'].getBoundingClientRect().top + 50;
      this.left = this.$refs['numeric-filter'].getBoundingClientRect().left;
      window.addEventListener('mousedown', this.onMouseDown);
      this.isOpen = true;
    },
  },
  computed: {
    getSelectedType() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getMinValue() {
      if (!this.min && this.max) {
        return false;
      }
      if (this.min) {
        return `${this.$t('from')} ${this.min}`;
      }
      return this.placeholder || this.$t('any');
    },
    getMaxValue() {
      return this.max ? `${this.$t('to')} ${this.max}` : false;
    },
    isNotEmptyValues() {
      const isStartType = this.type ? this.getSearchTypes[0].value : null;
      return this.min || this.max || this.currentSearchType !== isStartType;
    },
    getIntervals() {
      if (this.type === 'views') {
        return this.intervalsViews;
      }
      if (this.type === 'price') {
        return this.intervalsPrice;
      }
      return this.intervalsSubscribers;
    },
    getSearchTypes() {
      if (!this.type) {
        return false;
      }
      return groupsData[this.type].filter((type) => {
        if (!type.showFor) {
          return type.hideFor ? !type.hideFor.includes(this.getSelectedType) : type;
        }
        return type.showFor.includes(this.getSelectedType);
      });
    },
  },
  watch: {
    reset(newValue) {
      if (newValue === false) {
        this.initial();
      }
    },
    toMax() {
      this.initial();
    },
  },
};
</script>

<style lang="scss" scoped>
.numeric-filter {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
}

.numeric-filter__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5px;
}

.numeric-filter__title-active {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $primary-blue;
  margin-left: 10px;
  margin-top: 1px;
}

.numeric-filter__text {
  color: #565656;
  font-size: 14px;
  cursor: pointer;
  &_second {
    font-weight: 400;
    color: #7a7a7a;
    font-size: 13px;
  }
  &_input {
    color: $primary-lightgray;
    position: absolute;
    font-size: 11px;
    top: 13px;
    left: 10px;
  }
  &_small {
    color: $primary-lightgray;
    font-size: 11px;
    font-weight: 400;
  }
  &_active {
    font-size: 12px;
  }
  &_reset {
    margin-top: 20px;
  }
  &_blue {
    color: $primary-blue;
  }
}

.numeric-filter__menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 280px;
  z-index: 10;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
  background: $primary-white;
  cursor: auto;
}

.numeric-filter__menu-title {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.numeric-filter__menu-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 55px;
  @include between-elements {
    margin-right: 5px;
  }
}

.numeric-filter__menu-types {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
  @include between-elements {
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

.numeric-filter__input-block {
  position: relative;
  margin-bottom: 5px;
}

.numeric-filter__input {
  margin-top: 2px;
  padding: 8px 10px 8px 28px;
  border: 1px solid $border-color-primary;
  border-radius: 8px;
  line-height: 14px;
  background: $primary-white;
  width: 100%;
  font-size: 14px;
  -moz-appearance: textfield;
  &:focus {
    outline: none;
    border-color: $border-color-focus;
  }
  &::placeholder {
    color: rgb(161, 161, 161);
    font-size: 12px;
    font-weight: 400;
  }
}

.numeric-filter__intervals {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  @include between-elements {
    margin-bottom: 5px;
  }
}

.numeric-filter__interval {
  display: flex;
  align-items: center;
  height: 20px;
  @include between-elements {
    margin-right: 5px;
  }
}

.numeric-filter__slider {
  margin: 20px 0px 20px;
}
</style>
