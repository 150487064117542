<template>
  <div class="social-filter">
    <div
    class="social-filter__header"
    @click="isOpen = !isOpen">
      <div class="social-filter__header-name">
        <span class="social-filter__title">Социальные сети</span>
        <transition name="fastfade">
          <div
          v-if="isActiveFilter"
          class="social-filter__active-highlight" />
        </transition>
      </div>
      <DataIcon
      name="expand_more"
      class="social-filter__title pointer"
      :arrow-open="isOpen" />
    </div>
    <div
    class="social-filter__content"
    :class="isOpen && 'social-filter__content_show'">
      <DataSelect
      v-model="selectedSocial"
      placeholder="Выберите соц.сеть"
      :options="getTypesOfGroups"
      @input="setFilters" />
      <DataInput
      v-model="nameOrUrl"
      search-icon
      placeholder="Поиск по названию или ссылке"
      clear-icon
      @input="setFilters" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialFilter',
  props: {
  },
  data: () => ({
    isOpen: true,
    selectedSocial: null,
    nameOrUrl: '',
  }),
  created() {
    this.initData();
  },
  methods: {
    setFilters() {
      const query = { ...this.$route.query };
      if (this.nameOrUrl) {
        query.nameOrUrl = this.nameOrUrl;
      } else {
        delete query.nameOrUrl;
      }

      if (this.selectedSocial) {
        query.social = this.selectedSocial.value;
      } else {
        delete query.social;
      }

      this.$router.push({ name: 'post-search', query, params: { savedPosition: true } });
    },
    initData() {
      const { query } = this.$route;
      this.selectedSocial = query.social
        ? this.getTypesOfGroups.find((item) => item.value === query.social)
        : null;
      this.nameOrUrl = query.nameOrUrl || '';
    },
    resetFilters() {
      this.nameOrUrl = '';
      this.selectedSocial = null;
    },
  },
  computed: {
    getTypesOfGroups() {
      // FUTURE для сбера выпиливаем соц сеть инстаграм
      const isSber = this.getUserInfo?.asEmployerAgency?.id === 13 || this.getUserInfo?.asClientAgency?.id === 13;
      if (isSber) {
        return this.$store.getters['groups/getTypesOfGroups'].filter((social) => social.value !== 'instagram');
      }
      return this.$store.getters['groups/getTypesOfGroups'];
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    isActiveFilter() {
      return this.nameOrUrl || this.selectedSocial;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-filter {
  background: white;
  border-radius: 10px;
  padding: 0px 15px;

  .social-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
  }

  .social-filter__header-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .social-filter__title {
    font-size: 14px;
    color: $primary-gray;
    font-weight: 500;
    margin-right: 10px;
  }

  .social-filter__active-highlight {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $primary-blue;
  }

  .social-filter__content {
    height: 0px;
    opacity: 0;
    transition: 0.2s;
    pointer-events: none;
    @include between-elements {
      margin-bottom: 15px;
    }
    &_show {
      pointer-events: auto;
      opacity: 1;
      height: 130px;
    }
  }
}
</style>
