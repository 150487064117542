<template>
  <div
  ref="mainGroups"
  class="main-groups"
  :class="[
    isEmptyGroupsData && !isLoading && 'main-groups_empty',
    mode === 'noauth' && 'main-groups_noauth'
  ]">
    <EditCompilationBar v-if="isEditCompilationMode" />
    <NavActions
    :groups-data="groupsData"
    :mode="mode"
    :is-admin="isAdmin"
    :selected-groups="getSelectedGroups"
    :empty-data="isEmptyGroupsData" />
    <div
    ref="groupContent"
    class="main-groups__content"
    :class="{
      'main-groups__content_empty' : isEmptyGroupsData && !isLoading,
      'main-groups__content_hidden': getIsDemoCatalog && mode !== 'noauth'
    }"
    @scroll="onScroll"
    @mousedown.left="mouseDown"
    @mouseup.left="mouseUp"
    @mouseleave="mouseLeave"
    @mousemove="mouseMove">
      <div
      v-if="!isEmptyGroupsData"
      ref="groupElements"
      class="main-groups__elements">
        <GroupsHeaders
        :is-admin="isAdmin"
        :is-manager="isManager"
        :is-hidden-mode="isHiddenMode"
        :mode="mode"
        :selected-groups="getSelectedGroups"
        :is-all-selected="isAllSelected"
        :columns="columns"
        @clickCheckbox="selectAllGroups"
        @scrollToTop="scrollToTop" />
        <Group
        v-for="(group, index) in groupsData"
        :key="group.name + group.id + index"
        :group="group"
        :selected-groups="getSelectedGroups"
        :group-is-checked="groupIsChecked(group)"
        :selected-type-of-groups="getSelectedTypeOfGroups"
        :hide-group-details="getHideGroupDetails"
        :is-hidden-mode="isHiddenMode"
        :is-admin="isAdmin"
        :is-agency="isAgency"
        :is-manager="isManager"
        :mode="mode"
        :hide-group-menu="showGroupMenu"
        :columns="columns"
        @clickCheckbox="(value) => setGroupIsChecked(value, group)" />
      </div>
    </div>
    <div
    v-if="isEmptyGroupsData && !isLoading"
    class="main-groups__groups-empty flex-center">
      <span class="main-groups__text">{{ labelForEmptyGroups }}</span>
      <span
      v-if="!isShowFavoriteGroups"
      class="main-groups__text">{{ $t('try_updating_the_search_parameters') }}</span>
    </div>
    <transition name="fade">
      <div
      v-show="scrollPosition > 1320"
      class="main-groups__up-button"
      :class="showActions && 'main-groups__up-button_show-actions'"
      @click="scrollToTop">
        <span class="main-groups__up-button-text">{{ $t('up') }}</span>
        <DataIcon
        name="north"
        class="main-groups__up-button-icon"
        :size="16"
        pointer />
      </div>
    </transition>
    <GroupActions
    v-if="showGroupActions"
    :mode="mode"
    :groups-data="groupsData"
    :selected-groups="getSelectedGroups"
    :show="showActions" />
    <DemoBar
    v-if="getIsDemoCatalog && mode !== 'noauth'"
    :hide-group-details="getHideGroupDetails" />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import groupsData from '@/assets/js/groupsData';

import NavActions from './components/NavActions/NavActions.vue';
import GroupsHeaders from './components/GroupsHeaders.vue';
import Group from './components/Group/Group.vue';
import GroupActions from './components/GroupActions/GroupActions.vue';
import DemoBar from './components/DemoBar.vue';
import EditCompilationBar from './components/EditCompilationBar.vue';

export default {
  components: {
    NavActions, GroupsHeaders, Group, GroupActions, DemoBar, EditCompilationBar,
  },
  props: {
    groupsData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: null,
    },
    customColumns: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    scrollPosition: 0,
    columns: null,
    isScroll: false,
    startX: null,
    scrollLeft: null,
  }),
  beforeDestroy() {
    this.$store.commit('compilations/setSelectedCompilationList', []);
    this.$store.commit('groups/setAddToCompilationMode', false);
  },
  created() {
    const savedGroupsColumns = JSON.parse(localStorage.getItem('savedGroupsColumns240124'));
    if (savedGroupsColumns) {
      savedGroupsColumns.unshift({
        name: 'name',
        value: 'name',
        show: true,
        sort: 'name',
      });
    }
    this.columns = savedGroupsColumns || cloneDeep(groupsData.columns);

    // custom selected columns (in compilation)
    if (this.customColumns && this.customColumns.length) {
      this.columns = this.columns.filter((item) => item.name === 'name' || this.customColumns.includes(item.name));
    }

    // Скрытие прайса для агентств, у которых свойство hideTradeOfferWithOurBuyerType === true
    if (this.isAgency) {
      const hidePrice = this.getUserInfo?.asEmployerAgency?.hideTradeOfferWithOurBuyerType || false;
      if (hidePrice) {
        this.columns = this.columns.filter((item) => item.value !== 'price_buy');
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$refs.groupContent.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll() {
      const position = this.$refs.groupContent.scrollTop;
      const { clientHeight } = this.$refs.groupContent;

      if (position > this.scrollPosition) {
        this.$refs.mainGroups.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      this.scrollPosition = position;

      if (this.$refs.groupContent.scrollHeight <= clientHeight
      || this.isLastPage
      || this.isShowSelectedGroups
      || this.isShowFavoriteGroups
      || this.isLoading) {
        return;
      }

      const endScroll = Math.ceil(position + clientHeight) >= Math.ceil(this.$refs.groupContent.scrollHeight);

      if (endScroll) {
        this.$store.dispatch('groups/loadGroupsData');
      }
    },
    mouseDown(e) {
      if (e.target.className === 'group-name__block'
      || e.target.className === 'group-name'
      || e.target.className.includes('input')
      || e.target.className.includes('editor')) return;
      this.isScroll = true;
      this.startX = e.pageX - this.$refs.groupContent.offsetLeft;
      this.scrollLeft = this.$refs.groupContent.scrollLeft;
    },
    mouseLeave() {
      this.isScroll = false;
    },
    mouseUp() {
      this.isScroll = false;
    },
    mouseMove(e) {
      if (!this.isScroll || this.showGroupMenu) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.groupContent.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.$refs.groupContent.scrollLeft = this.scrollLeft - walk;
    },
    groupIsChecked(group) {
      return this.getSelectedGroups.length > 0
        ? this.getSelectedGroups.some((item) => (item.name === group.name && item.id === group.id))
        : false;
    },
    setGroupIsChecked(value, group) {
      if (value) {
        const newSelectedGroups = [...this.getSelectedGroups, group];
        this.$store.commit('groups/setSelectedGroups', newSelectedGroups);
        return;
      }
      const newSelectedGroups = this.getSelectedGroups.filter((item) => !(item.id === group.id && item.name === group.name));
      this.$store.commit('groups/setSelectedGroups', newSelectedGroups);
    },
    selectAllGroups(value) {
      if (!value) {
        const groupsToUnselected = this.getSelectedGroups.filter((item) => !this.groupsData.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('groups/setSelectedGroups', groupsToUnselected);
        if (this.isShowSelectedGroups && !groupsToUnselected.length) {
          this.$store.commit('collections/setShowSelectedGroups', false);
        }
      } else {
        const groupsToSelected = this.groupsData.filter((item) => !this.getSelectedGroups.some((el) => el.id === item.id && el.name === item.name));
        this.$store.commit('groups/setSelectedGroups', [...this.getSelectedGroups, ...groupsToSelected]);
      }
    },
  },
  computed: {
    isEmptyGroupsData() {
      return this.groupsData.length === 0;
    },
    isEmptySelectedGroups() {
      return this.getSelectedGroups.length === 0;
    },
    getSelectedGroups() {
      return this.$store.getters['groups/getSelectedGroups'];
    },
    getSelectedTypeOfGroups() {
      return this.$store.getters['groups/getSelectedTypeOfGroups'];
    },
    getHideGroupDetails() {
      return this.$store.getters['groups/getHideGroupDetails'];
    },
    isShowSelectedGroups() {
      return this.$store.getters['collections/getShowSelectedGroups'];
    },
    isShowFavoriteGroups() {
      return this.$store.getters['favorites/getShowFavoriteGroups'];
    },
    isAgency() {
      return this.$store.getters['user/getIsAgency'];
    },
    isManager() {
      return this.$store.getters['user/getIsManager'];
    },
    isLoading() {
      return this.$store.getters['app/getAppIsLoading'];
    },
    labelForEmptyGroups() {
      return this.isShowFavoriteGroups ? this.$t('Favorites is empty') : this.$t('Nothing found');
    },
    isAdmin() {
      return this.$store.getters['user/getIsAdmin'];
    },
    isLastPage() {
      return this.$store.getters['groups/getCurrentPage'] === (+this.$store.getters['groups/getTotalPageCounts'] + 1);
    },
    isHiddenMode() {
      return this.$store.getters['groups/getGroupsSearchParams'].onlyBadGroups;
    },
    showActions() {
      return !this.isEmptySelectedGroups || this.isShowSelectedGroups;
    },
    showGroupMenu() {
      return this.$store.getters['groups/getShowGroupMenu'];
    },
    showGroupActions() {
      // показываем синюю плашку если список групп не пуст или это подборка (в демо режиме не показываем)
      return !this.isEmptyGroupsData;
    },
    getIsDemoCatalog() {
      return this.$store.getters['user/getIsDemoCatalog'];
    },
    isAllSelected() {
      return this.groupsData.every((item) => this.getSelectedGroups.some((el) => el.name === item.name));
    },
    getUserInfo() {
      return this.$store.getters['user/getUserInfo'];
    },
    isEditCompilationMode() {
      return this.$store.getters['groups/getAddToCompilationMode'];
    },
  },
  watch: {
    groupsData() {
      if (this.groupsData.length < 15) {
        this.scrollPosition = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-groups {
  background: $primary-white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  position: relative;
  margin: 20px 0px 0px;
  flex: 1;
  @include tablet-screen {
    margin: 20px;
  }
  &_noauth {
    max-height: calc(100vh - 162px);
  }
  &_empty {
    height: 60vh;
  }
}

.main-groups__content {
  background: white;
  z-index: 1;
  overflow: auto;
  overflow-x: scroll;
  width: 100%;
  height: calc(100vh - 90px);
  &_empty {
    height: 0px;
  }
  &_hidden {
    overflow-y: hidden;
  }
}

.main-groups__groups-empty {
  flex-direction: column;
  height: 100%;
}

.main-groups__text {
  color: $primary-black;
  font-size: 14px;
  &_all-selected {
    color: white;
  }
}

.main-groups__elements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-groups__up-button {
  display: none;
    @include desktop-screen {
    position: absolute;
    padding: 5px 20px;
    bottom: 40px;
    right: 40px;
    color: $primary-white;
    font-size: 12px;
    transition: 0.2s;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    background: $primary-blue;
    align-items: center;
    &_show-actions {
      bottom: 100px;
    }
  }
}

</style>
