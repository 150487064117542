<template>
  <div class="notifications">
    <!-- Main Icon -->
    <div
    class="notifications__icon-block"
    @click="openNotifications">
      <DataIcon
      ref="bellIcon"
      name="notifications"
      class="notifications__icon"
      :class="showBellAnimation && 'notifications__icon_bell'"
      :size="24" />
      <transition name="fade">
        <div
        v-if="activeLabel"
        class="notifications__active-label" />
      </transition>
    </div>

    <!-- NotificationsList -->
    <transition name="fade">
      <NotificationsList
      v-if="showNotificationsList"
      :new-notifications="newNotifications"
      :has-new-notifications="hasNewNotifications"
      :speaker-state="speakerState"
      @checkNewNotifications="checkNewNotifications"
      @toggleSpeakerState="toggleSpeakerState"
      @closeNotificationsList="closeNotificationsList" />
    </transition>

    <audio
    ref="sound"
    :src="require('./assets/ring.mp3')"
    style="display:none"
    muted />
  </div>
</template>

<script>
import io from 'socket.io-client';
import NotificationsList from './components/NotificationsList.vue';

export default {
  name: 'Notifications',
  components: { NotificationsList },
  data: () => ({
    showNotificationsList: false,
    socket: null,
    activeLabel: false,
    newNotifications: [],
    showBellAnimation: false,
    hasNewNotifications: false,
    speakerState: 'Active',
    faviconName: 'default',
    faviconChangeInterval: null,
  }),
  created() {
    this.initSocket();
    this.getNotifications();
    this.speakerState = localStorage.getItem('speakerState') || 'Active';
  },
  beforeDestroy() {
    this.$emit('close');
  },
  methods: {
    getNotifications() {
      this.$store.dispatch('notifications/getNotificationsList')
        .then((data) => {
          if (data.length) {
            this.newNotifications = data.filter((item) => item.status === 1) || [];
            this.hasNewNotifications = this.newNotifications.length > 0;
            this.activeLabel = this.hasNewNotifications;
            this.showNotificationsList = this.hasNewNotifications;
          }
        });
    },
    initSocket() {
      const userId = this.$store.getters['user/getUserInfo'].id;
      if (!userId) {
        this.$emit('hideNotifications');
        return;
      }

      const url = process.env.NODE_ENV === 'development' ? 'https://stage-datafront.socialjet.pro/notifications' : 'https://data.socialjet.pro/notifications';
      this.socket = io(url);
      this.socket.emit('join', { room: `user_id_${userId}` }); // id пользователя

      this.socket.on('connect', () => {
        console.log('Notification connected');
      });

      this.socket.on('receive-events', (data) => {
        this.handleNewNotification(data);
      });
    },
    handleNewNotification(data) {
      const list = [...this.$store.getters['notifications/getNotificationsList'], data];
      this.$store.commit('notifications/setNotificationsList', list);

      if (this.newNotifications.length) {
        this.newNotifications.push(data);
      } else {
        this.newNotifications = [data];
      }

      this.hasNewNotifications = true;
      this.toggleBell();
      this.openNotifications();
      this.activeLabel = true;

      if (this.speakerState === 'Active') {
        this.$nextTick(() => {
          this.$refs.sound.play();
          if (!this.faviconChangeInterval) this.changeFavicon('start');
        });
      }
    },
    openNotifications() {
      if (this.showNotificationsList) return;
      this.showNotificationsList = true;
      this.activeLabel = false;
      this.changeFavicon('stop');
    },
    closeNotificationsList() {
      this.showNotificationsList = false;
      this.activeLabel = false;
      this.hasNewNotifications = false;
      this.newNotifications = [];
      this.changeFavicon('stop');
    },
    toggleBell() {
      if (this.showBellAnimation) return;

      this.showBellAnimation = true;
      setTimeout(() => {
        this.showBellAnimation = false;
      }, 3000);
    },
    checkNewNotifications() {
      this.hasNewNotifications = false;
      this.newNotifications = [];
    },
    toggleSpeakerState() {
      this.speakerState = this.speakerState === 'Active' ? 'Disabled' : 'Active';
      localStorage.setItem('speakerState', this.speakerState);
    },
    changeFavicon(value) {
      const faviconItem = document.querySelector("link[rel*='icon']");
      if (value === 'stop') {
        this.faviconName = 'default';
        clearInterval(this.faviconChangeInterval);
        this.faviconChangeInterval = false;
        faviconItem.href = 'https://data-api.socialjet.pro/icon/favicon.svg';
        return;
      }

      this.faviconChangeInterval = setInterval(() => {
        if (this.faviconName === 'default') {
          faviconItem.href = 'https://data-api.socialjet.pro/icon/favicon-notify.svg';
          this.faviconName = 'notify';
        } else {
          faviconItem.href = 'https://data-api.socialjet.pro/icon/favicon.svg';
          this.faviconName = 'default';
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  position: relative;

  .notifications__icon-block {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .notifications__icon {
    color: rgba($primary-gray, 0.9);
    &_bell {
      animation: ring 5s linear infinite;
    }
  }

  .notifications__active-label {
    width: 10px;
    height: 10px;
    background: $primary-green;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: -5px;
  }

  .notifications__empty {
    box-shadow: 0px 0px 10px 1px rgb(34 60 80 / 10%);
    height: 40px;
    border-radius: 8px;
    width: 250px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    right: -90px;
  }

  .notifications__empty-text {
    font-size: 14px;
    color: $primary-gray;
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}
}
</style>
